.header {
    background-color: #4a5568;
    padding: 16px;
    color: white;
}

.nav-list {
    display: flex;
    align-items: center;
    gap: 16px;
}

.nav-logo-container {
    flex-shrink: 0;
}

.nav-logo {
    height: 32px; 
    width: 32px; 
    margin-right: 16px; 
}

.nav-link {
    color: #d1d5db; 
    background-color: transparent;
    padding: 8px 12px; 
    font-size: 0.875rem; 
    font-weight: medium;
    border-radius: 4px; 
    transition: background-color 0.3s, color 0.3s;
  }
  
  .nav-link:hover {
    background-color: #4a5568; 
    color: white; 
  }

  .nav-list li:last-child {
    margin-left: auto;
}