.track-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
  margin: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.track-container:hover {
  background-color: #f2f2f2;
}

.track-image {
  width: 150px;
  height: 150px;
  border-radius: 0.5rem;
  transition: transform 0.3s;
}

.track-image:hover {
  transform: scale(1.05);
}

.track-info {
  overflow: hidden;
}

.track-title,
.track-artist {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.track-title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
}

.track-artist {
  font-size: 0.875rem;
  color: #6c757d;
  line-height: 1.2;
}
