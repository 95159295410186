.home-container {
  text-align: center;
  padding: 2rem;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.title {
  font-size: 2.5rem; 
  font-weight: 700; 
  color: #333; 
  margin-bottom: 2rem;
  margin-top: 2rem; 
  text-transform: uppercase; 
  letter-spacing: 0.05em; 
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
  text-align: center; 
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.card-container {
  width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; 
  transition: transform 0.2s;
}

.card-container:hover {
  transform: scale(1.05); 
}

.card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-content {
  padding: 1rem;
}

.refresh-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.refresh-button:hover {
  background-color: #45a049; 
}
