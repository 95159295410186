.card-container {
    max-width: 20rem;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .card-image {
    width: 100%;
  }
  
  .card-content {
    padding: 1.5rem;
    flex: 1;
  }
  
  .font-bold {
    font-weight: bold;
    font-size: 1.20rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;    
    overflow: hidden;          
    text-overflow: ellipsis;   
  }
  
  .text-base {
    color: #4a5568;
    font-size: 1rem;
  }
  
  .card-footer {
    padding: 1.5rem;
    align-self: center;
  }
  
  .card-songs {
    display: inline-block;
    background-color: #edf2f7;
    border-radius: 9999px;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #4a5568;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  