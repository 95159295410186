.dropdown-container {
    position: relative;
    display: inline-block;
    text-align: left;
  }
  
.login-button {
    color: white;
    background-color: #000000 !important;
    padding: 0.625rem 1.5rem; 
    font-size: 0.875rem; 
    border-radius: 0.5rem; 
    font-weight: 500; 
    display: inline-flex;
    align-items: center;
    justify-content: center; 
    margin-right: 0.5rem; 
    border: none; 
    box-sizing: border-box;
}

.login-button:hover {
background-color: rgba(5, 7, 8, 0.9); 
}

.login-button:focus {
box-shadow: 0 0 0 4px rgba(5, 7, 8, 0.5); 
outline: none; 
}

.login-button.dark:focus {
box-shadow: 0 0 0 4px rgba(5, 7, 8, 0.5); 
}

.login-button.dark:hover {
background-color: rgba(5, 7, 8, 0.3); 
}

.dropdown-menu {
position: absolute;
top: 100%;
right: 0;
width: 14rem; 
margin-top: 0.5rem; 
border-radius: 0.375rem; 
box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
background-color: white; 
outline: none;
}

.dropdown-content {
padding-top: 0.25rem; 
}

.dropdown-item {
display: block;
padding: 0.5rem 1rem; 
font-size: 0.875rem; 
color: #4b5563; 
text-decoration: none;
}

.dropdown-item:hover {
background-color: #f3f4f6; 
}  