@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
body {
  font-family: 'Roboto', sans-serif;
}
@media (max-width: 600px) {
    .playlists-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
.playlists-container {
    padding: 20px;
  }
  
  .playlists-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  .playlist-card {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
    animation: fadeIn 1s;
    background-color: #a7b0aa;
    color: #ffffff;
  }
  .playlist-card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    transform: translateY(-5px);
    background-color: #1ED760;
  }
  
  .playlist-image {
    max-width: 100%;
    border-radius: 10px;
  }
  
  .playlist-name {
    margin-top: 10px;
  }
  